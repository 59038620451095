html, body, #app, #app>div {
  height: 100%;
  margin: 0;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  min-height: 100vh;
  position: relative;
}
 